<template>
  <form v-if="task!=null" @submit.prevent="saveTask">
    <div class="form-row-body">
      <FormRow forId="row-title" label="Title">
        <PriInputText
          id="row-title"
          ref="title"
          type="text"
          v-model="task.content"
          class="w-full"
          :class="{'p-invalid': errors.title}"
          />
      </FormRow>

      <FormRow label="Status">
        <PriDropdown v-model="task.status" :options="statusOptions"
                     optionLabel="label" optionValue="value"/>
      </FormRow>

      <FormRow label="Assignee">
        <PriDropdown v-if="memberOptions"
          v-model="assigneeId" :options="memberOptions"
          optionLabel="label" optionValue="val" />
      </FormRow>
    </div>
    <div class="footer">
      <PriButton type="submit" label="Save"/>
    </div>
  </form>
</template>

<script>
import { SpaAxios } from '@/request';
import FormRow from '@/common/FormRow.vue';
import * as Task from '@/task';

export default {
  inject: ['dialogRef'],
  components: { FormRow },
  data() {
    return {
      task: null, // { id, content, status, assigneeId },
      assigneeId: null,
      memberOptions: null,
      errors: {},
    };
  },

  created() {
    this.statusOptions = Task.statusOptions;
  },

  mounted() {
    // reset so do not show members from previous task
    this.memberOptions = null;
    this.task = this.dialogRef.data.task;
    this.assigneeId = this.task.assignee ? this.task.assignee.id : null;
    this.$nextTick(() => {
      this.$refs.title.$el.focus();
    });
    const respProm = SpaAxios.get(`/api/team/${this.task.team.id}/members`)
          .then((res) => {
            const data = res.data;
            const options = [];
            for (const member of data.members) {
              options.push({
                val: member.userId,
                label: member.first,
              });
            }
            this.memberOptions = options;
          });
    this.$handleError(respProm);
  },

  methods: {
    statusLabel(status) {
      return this.status[status];
    },
    saveTask() {
      const postData = {
        taskId: this.task.id,
        content: this.task.content,
        status: this.task.status,
        assigneeId: this.assigneeId,
      };
      this.dialogRef.options.save(postData);
      this.dialogRef.close();
    },
  },
}
</script>


<style scoped>
  .footer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
</style>
