<template>
  <div class="body">
    <p>A new version of the application is available.</p>
    <p>Please refresh the page to load the lastest version.</p>
    <div class="footer">
      <PriButton label="Refresh" @click="refreshPage"/>
    </div>
  </div>
</template>


<script>
export default {
  inject: ['dialogRef'],
  data() {
    return {
    };
  },

  methods: {
    refreshPage() {
      this.dialogRef.close();
      window.location.reload()
    },
  },
}
</script>

<style scoped>
.footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
}
</style>
