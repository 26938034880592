<script>
export default {
  props: {
    forId: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    info: {
      type: String,
    },
    infoTop: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorIn: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: true,
    },
  },

  emits: [],

  data() {
    return {
      error: null,
    };
  },

  methods: {
    validateAll() {
      if (this.required && !this.modelValue) {
        this.error = 'Required field';
        return false;
      }
      return true;
    },

    onBlur() {
      this.validateAll();
    },

    onFocus() {
      // clear error on focus otherwise user thinks input is still invalid
      this.error = null;
    },

    changed(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  },
};
</script>


<template>
  <div class="form-row-field"
       :class="{
               'error-highlight': errorIn,
               'border-bottom-no': !borderBottom,
               }">
    <div class="form-label flex-column">
      <label v-if="forId" :for="forId">{{label}}:</label>
      <span v-else>{{label}}:</span>
      <!-- FIXME: where to display errors -->
      <!-- <span v-if="error" class="p-error">{{error}}</span> -->
      <slot name="filter"></slot>
    </div>
    <div style="width:100%">
      <div v-if="infoTop" class="info">{{infoTop}}</div>
      <slot></slot>
      <div v-if="info" class="info">{{info}}</div>
    </div>
  </div>
</template>


<style scoped>
.form-label {
  flex-shrink: 0;
  width: 200px;
  color: var(--global-textSecondaryColor, #6B7280);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.info {
  margin-top: 8px;
  color: var(--global-textSecondaryColor, #6B7280);
  /* text-sm/regular/lineheight-auto */
  font-family: Inter;
  font-size: 12.25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.error-highlight {
  outline: 2px solid var(--error-color);
  outline-offset: 2px;
}

.border-bottom-no {
  border-bottom: unset;
}
</style>
