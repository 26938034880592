<template>
  <PriCard style="width: 25rem; overflow: hidden">
    <template #header>
    </template>
    <template #title>{{ data.title }}</template>
    <template #subtitle>
      <span v-for="kind in data.kind" style="margin-right: 0.5rem;" :key="kind">
        <EpTag :value="kind" color="teal"/>
      </span>
    </template>
    <template #content>
      <p>{{ data.subtitle }}</p>
    </template>
    <!-- <template #footer> -->
    <!--   {{ data.columns }} -->
    <!-- </template> -->
  </PriCard>
</template>

<script>
import EpTag from "@/common/EpTag.vue";

export default {
  components: { EpTag },
  props: {
    data: { // {title, subtitle, kind}
      required: true,
    },
  },
}
</script>
