import { createApp, nextTick } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import posthogPlugin from './plugins/posthog';
import mixpanelPlugin from './plugins/mixpanel';

import './assets/emprove-pri-theme.css';
import './assets/main.css';

import { vueInstallAjaxErrorHandler } from './request';

// Form
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';
import Password from 'primevue/password';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import Knob from 'primevue/knob';
import FloatLabel from 'primevue/floatlabel';

// Messages
import Message from 'primevue/message';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';

// Misc
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Chip from 'primevue/chip';
import Inplace from 'primevue/inplace';
import Tag from 'primevue/tag';
import ProgressSpinner from 'primevue/progressspinner';

// Panel
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Panel from 'primevue/panel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Toolbar from 'primevue/toolbar';

// Overlay
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import OverlayPanel from 'primevue/overlaypanel';

// Menu
import Breadcrumb from 'primevue/breadcrumb';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Steps from 'primevue/steps';

// Data
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import OrderList from 'primevue/orderlist';

import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';

import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(PrimeVue);
app.directive('badge', BadgeDirective);

// Form
app.component('PriCheckbox', Checkbox);
app.component('PriCalendar', Calendar);
app.component('PriInputText', InputText);
app.component('PriInputNumber', InputNumber);
app.component('PriInputSwitch', InputSwitch);
app.component('PriDropdown', Dropdown);
app.component('PriSelectButton', SelectButton);
app.component('PriToggleButton', ToggleButton);
app.component('PriPassword', Password);
app.component('PriTextarea', Textarea);
app.component('PriButton', Button);
app.component('PriSplitButton', SplitButton);
app.component('PriKnob', Knob);
app.component('PriFloatLabel', FloatLabel);

// Messages
app.component('PriMessage', Message);
app.component('PriToast', Toast);
app.use(ToastService)
// Misc
app.component('PriAvatar', Avatar);
app.component('PriAvatarGroup', AvatarGroup);
app.component('PriBadge', Badge);
app.component('PriChip', Chip);
app.component('PriInplace', Inplace);
app.component('PriTag', Tag);
app.component('PriProgressSpinner', ProgressSpinner);
// Panel
app.component('PriAccordion', Accordion);
app.component('PriAccordionTab', AccordionTab);
app.component('PriCard', Card);
app.component('PriDivider', Divider);
app.component('PriPanel', Panel);
app.component('PriTabView', TabView);
app.component('PriTabPanel', TabPanel);
app.component('PriStepper', Stepper);
app.component('PriStepperPanel', StepperPanel);
app.component('PriToolbar', Toolbar);

// Overlay
app.use(ConfirmationService);
app.use(DialogService);
app.component('PriConfirmDialog', ConfirmDialog);
app.component('PriDialog', Dialog);
app.directive('tooltip', Tooltip);
app.component('PriOverlayPanel', OverlayPanel);

// Menu
app.component('PriBreadcrumb', Breadcrumb);
app.component('PriMenu', Menu);
app.component('PriMenubar', Menubar);
app.component('PriSteps', Steps);
// Data
app.component('PriDataTable', DataTable);
app.component('PriColumn', Column);
app.component('PriOrderList', OrderList);


if (process.env.NODE_ENV === 'production') {
  /*
    1) sentry
    2) posthog
    3) mixpanel
    4) Google analytics
    5) hotjar
    RELOAD full page on js code update
  */

  // // SENTRY
  // Sentry.init({
  //   app,
  //   dsn: "https://8184730e7b7c425fa57a28f7e840d6d2@o4506929290477568.ingest.us.sentry.io/4506929296769025",
  //   integrations: [
  //     // Sentry.browserTracingIntegration({ router }),
  //     // Sentry.replayIntegration({
  //     //     maskAllText: false,
  //     //     blockAllMedia: false,
  //     // }),
  //   ],
  // });

  // POSTHOG
  // --- remove posthog because Chris complain of slowdown 2024-05-02
  // app.use(posthogPlugin);
  // // configure vue-router to trigger posthog pageview events
  // router.afterEach((to, from, failure) => {
  //   if (!failure) {
  //     nextTick(() => {
  //       app.config.globalProperties.$posthog.capture(
  //         '$pageview', { path: to.fullPath });
  //     });
  //   }
  // });


  // MIXPANEL
  app.use(mixpanelPlugin);
  router.beforeEach((to, from, next) => {
    const routePath = to.fullPath;
    app.config.globalProperties.$mixpanel.track('Page View SPA',
                                                { page: routePath });
    next();
  });

  // GA
  window.dataLayer = window.dataLayer || [];
  function gtag(){ dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', 'G-H5T7CZ3ZHN');

  // HOTJAR
  //  <!-- Hotjar Tracking Code for Site 5005911 (name missing) -->
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:5005911,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

  // UPDATE JS
  // reload page if tries to dynamic import stale componenet version
  // https://vitejs.dev/guide/build#load-error-handling
  window.addEventListener('vite:preloadError', () => {
    alert("A new version of the application is available. Refresh page to load the latest vresion.");
    window.location.reload(); // for example, refresh the page
  });
  // Another solution - seems more drastic, use if above dont work
  // // https://github.com/vitejs/vite/issues/11804#issuecomment-1406182566
  // router.onError((error, to) => {
  //   if (error.message.includes('Failed to fetch dynamically imported module')) {
  //     window.location = to.fullPath
  //   }
  // });
} // end if PRODUCTION

app.use(VueShepherdPlugin);
app.use(vueInstallAjaxErrorHandler);
app.mount('#app');
