
function convertUnicodeToEmoji(unicode) {
  try {
    return String.fromCodePoint(parseInt(unicode.substring(2), 16));
  } catch(error) {
    return '';
  }
}

function emojiToUnicode(emoji) {
  const codePoint = emoji.codePointAt(0);
  const unicodeString = 'U+' + codePoint.toString(16).toUpperCase().padStart(4, '0');
  return unicodeString;
}

const AccessPublicOpt = {
    true: { label: 'Public', value: true, icon: 'pi-lock-open' },
    false: {label: 'Team Only', value: false, icon: 'pi-lock'},
};


function downloadICS(event) {
  // Construct iCalendar data
  // const event = {
  //   summary: "Your Event Title",
  //   uid: "emprove-meeting-36",
  //   tstamp: "20220412T100000Z",
  //   start: "20220412T100000Z",
  //   end: "20220412T120000Z",
  //   location: "Your Event Location",
  //   description: "Event Description"
  // };

  // validator
  // https://icalendar.org/validator.html

  const calBegin = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:emprove.io
`;
  const calEnd ="\nEND:VCALENDAR"

  const calEvent = [];
  calEvent.push('BEGIN:VEVENT');
  calEvent.push(`UID:${event.uid}`);
  if (event.tstamp) {
    calEvent.push(`DTSTAMP:${event.tstamp}`);
  }
  calEvent.push(`SUMMARY:${event.summary}`);
  calEvent.push(`DTSTART:${event.start}`);
  if (event.end) {
    calEvent.push(`DTEND:${event.end}`);
  }
  if (event.location) {
    calEvent.push(`LOCATION:${event.location}`);
  }
  if (event.description) {
    calEvent.push(`DESCRIPTION:${event.description}`);
  }
  calEvent.push(`END:VEVENT`);
  const icsContent = calBegin + calEvent.join('\n') + calEnd;

  // Create a Blob containing the iCalendar data
  const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });

  // Create a temporary anchor element to trigger the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'emprove-event.ics';

  // Trigger the download
  link.click();

  // Clean up
  URL.revokeObjectURL(link.href);
}



export { convertUnicodeToEmoji, emojiToUnicode, AccessPublicOpt, downloadICS };
