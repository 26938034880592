<template>
  <div class="board-head">
    <div v-for="(col, colIdx) in columns" :key="colIdx"
         class="board-head-col">
      <div class="board-col-header">
        <h3 class="col-title">{{col.title}}
          <span v-if="col.emoji" class="emoji-img"
                v-html="convertUnicodeToEmoji(col.emoji)" />
        </h3>
        <div class="col-desc">{{col.desc}}</div>
        <div v-if="previewColor" style="width: 100%; padding: 1rem;"
             :style="{'background-color': col.color}">
          {{col.color}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertUnicodeToEmoji } from '@/util';

export default {
  components: { },
  props: {
    columns: {
      required: true,
    },
    previewColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    convertUnicodeToEmoji,
  },
}
</script>

<style scoped>
  .board-col-header {
    display: flex;
    padding: 17.5px;
    flex-direction: column;
    gap: 8px;

    border-radius: 12px 12px 0px 0px;
    background: var(--bluegray-100);
    box-shadow: inset 0 0 1px #E5E7EB;
    height: 100%;

    .col-title {
      /* text-2xl/bold/lineheight-auto */
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .col-desc {
      /* text-base/semibold/lineheight-auto */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
</style>
