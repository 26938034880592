<template>
  <div class="container">
    <div class="call-text">
      <!-- Vote&nbsp; -->
    </div>
    <div class="vote-control">
      <PriButton
        :disabled="voteCount===0"
        size="small"
        icon="pi pi-minus"
        @click="emitVote('voteMinus')"/>
      <PriBadge :value="voteCount" severity="info" size="large"/>
      <PriButton
        :disabled="userVoteRemaining<=0 || submitting"
        size="small"
        @click="emitVote('votePlus')"
        icon="pi pi-plus"/>
    </div>
</div>
</template>

<script>
export default {
  emits: ['votePlus', 'voteMinus'],
  components: { },
  props: {
    voteCount: {
      type: Number,
      required: true,
    },
    userVoteRemaining: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
    };
  },
  watch: {
    userVoteRemaining() {
      this.submitting = false;
    },
  },
  methods: {
    emitVote(evName) {
      this.submitting = true;
      this.$emit(evName);
    },
  },
}
</script>

<style scoped>
  .container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px;
  }

  .vote-control {
    align-self: flex-end;
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .p-button.p-button-icon-only {
    width: 1rem !important;
    padding: 6px 14px;
  }
</style>
