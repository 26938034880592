<template>
  <span class="tag" :class="`color-${color}`">
    {{ value }}
  </span>
</template>

<script>

export default {
  props: {
    value: {
      required: true,
    },
    color: {
      default: 'surface',
    },
  },

  methods: {
  },
}
</script>

<style scoped>
  .tag {
    font-size: 0.7rem;
    font-weight: 400;
    padding: 0.2rem 0.5rem 0.1rem;
    border-radius: 10px;
  }
  .color-teal {
    /* border: 1px solid var(--teal-700); */
    color: var(--teal-700);
    background: var(--teal-100);
  }
  .color-cyan {
    /* border: 1px solid var(--cyan-700); */
    color: var(--cyan-700);
    background: var(--cyan-100);
  }
</style>
