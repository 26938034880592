const boRoutes = [
  {
    path: '/bo',
    name: 'bo-home',
    component: () => import('@/bo/BoMain.vue'),
    children: [
      // Account
      {
        path: 'org',
        name: 'bo-org-list',
        component: () => import('@/bo/OrgList.vue'),
      },
      {
        path: 'org/new',
        name: 'bo-org-new',
        component: () => import ('@/bo/OrgDetail.vue'),
      },
      {
        path: 'org/:id',
        name: 'bo-org-detail',
        component: () => import ('@/bo/OrgDetail.vue'),
      },
      {
        path: 'user',
        name: 'bo-user-list',
        component: () => import('@/bo/UserList.vue'),
      },
      {
        path: 'user/:id',
        name: 'bo-user-detail',
        component: () => import ('@/bo/UserDetail.vue'),
      },

      // Retro Board Templates
      {
        path: 'board',
        name: 'bo-board-list',
        component: () => import('@/bo/BoardList.vue'),
      },
      {
        path: 'board/new',
        name: 'bo-board-new',
        component: () => import ('@/bo/BoardDetail.vue'),
      },
      {
        path: 'board/:id',
        name: 'bo-board-edit',
        component: () => import ('@/bo/BoardDetail.vue'),
      },


      // Retro
      {
        path: 'meeting',
        name: 'bo-meeting-list',
        component: () => import('@/bo/MeetingList.vue'),
      },


      // AI
      {
        path: 'prompts',
        name: 'bo-prompt-list',
        component: () => import('@/bo/PromptList.vue'),
      },
      {
        path: 'prompts/:id',
        name: 'bo-prompt-detail',
        component: () => import ('@/bo/PromptDetail.vue'),
      },
      // AI
      {
        path: 'ailog',
        name: 'bo-ailog-list',
        component: () => import('@/bo/AiLogList.vue'),
      },
      {
        path: 'ailog/:id',
        name: 'bo-ailog-detail',
        component: () => import ('@/bo/AiLogDetail.vue'),
      },
    ],
  },
];

export default boRoutes;
