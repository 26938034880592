<template>
  <div v-if="rate!==null">
    <div v-if="rate.team" class="knobs" style="padding: 1rem;">
      <RateGauge v-for="col in rateCols" :key="col"
                 :value="rate.team[col].score"
                 :label="col"
                 :info="analysisDef[col]"
                 />
    </div>
    <div v-if="viewIndividual && rate.members" class="subsection">
      <h2 style="margin-bottom: 0.5rem;">
        Individual Analysis
        <PriTag icon="pi pi-info-circle"
                severity="secondary"
                v-tooltip="'This analysis is only visible to Facilitators.'"
                value="Facilitator only"
                style="margin-left: 0.5rem;"
                />
      </h2>

      <div style="padding-top: 2rem;">
        <TblColor
          :columns="rateCols"
          :rows="rateRows"
          :values="rateVals"
          :selected="rateSelIdx"
          :selectedColumn="rateColumnIdx"
          :selectableCell="true"
          @selectCell="rateSelectCell($event)"
          style="margin:auto;"
          />
        <!-- <p class="tbl-note"> -->
        <!--   <i class="pi pi-info-circle"/> -->
        <!--   Click on a user's name to view details. -->
        <!-- </p> -->
      </div>

      <div v-if="rateSelName !== null">

        <div class="flex items-center row-user-selector">
          <i class="pi pi-search"/>
          Show the analysis of
          <PriButton outlined
                     @click="toggleIndividualMenu"
                     severity="contrast"
                     icon="pi pi-chevron-down"
                     icon-pos="right"
                     :label="rateSelName"/>
        </div>
        <PriMenu ref="individualMenu" :model="rateIndividualMenu" :popup="true"/>

        <PriTabView v-model:activeIndex="rateColumnIdx">
          <PriTabPanel header="Sentiment">
            <div>Score: {{rateSelDetail['sentiment'].score}}</div>
            <AnalysisText :items="rateSelDetail['sentiment'].reasoning"/>
          </PriTabPanel>
          <PriTabPanel header="Involvement">
            <div>Score: {{rateSelDetail['involvement'].score}}</div>
            <AnalysisText :items="rateSelDetail['involvement'].reasoning"/>
          </PriTabPanel>
          <PriTabPanel header="Alignment">
            <div>Score: {{rateSelDetail['alignment'].score}}</div>
            <AnalysisText :items="rateSelDetail['alignment'].reasoning"/>
          </PriTabPanel>
        </PriTabView>

      </div>
    </div>
  </div>
</template>

<script>

import TblColor from '@/common/TblColor.vue';
import RateGauge from '@/common/RateGauge.vue';
import AnalysisText from '@/common/AnalysisText.vue';

export default {
  components: {
    TblColor, RateGauge,
    AnalysisText,
  },
  props: {
    rate: {
      type: Object,
      required: true,
    },
    viewIndividual: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      rateIndividualMenu: null,
      // row
      rateSelIdx: null,
      rateSelName: null,
      rateSelDetail: null,
      // column
      rateColumnIdx: null,
    };
  },

  created() {
    this.rateCols = ['sentiment', 'involvement', 'alignment'];
    if (this.rate && this.rate.members) {
      this.rateSelect(0);
      this.initIndividualRateMenu();
    }

    this.analysisDef = {
      'sentiment': "The overall tone of the meeting, highlighting confidence, collaboration, and the willingness to share honest thoughts and feelings about work. Note that the tone is also dependent on the subject of the meeting.",
      'alignment': "When team members’ ideas and efforts consistently focus on shared goals and interests, showing unity and mutual understanding.",
      'involvement': "The team members’ commitment and proactive involvement in the meeting, from in-depth project knowledge and problem-solving to taking charge of tasks and driving improvements.",
    };
  },

  computed: {
    rateRows() {
      const rows = [];
      for(const member of this.rate.members) {
        rows.push(member.name);
      }
      // rows.push('Team');
      return rows;
    },

    rateVals() {
      const vals = [];
      for(const member of this.rate.members) {
        const row = [];
        for (const col of this.rateCols) {
          row.push(member[col].score);
        }
        vals.push(row);
      }
      // const teamRow = [];
      // for (const col of this.rateCols) {
      //   teamRow.push(this.rate.team[col].score);
      // }
      // vals.push(teamRow);
      return vals;
    },

    rateReasons() {
      const members = {};
      for(const member of this.rate.members) {
        const reasons = {};
        for (const col of this.rateCols) {
          reasons[col] = member[col].reasoning;
        }
        members[member.name] = reasons;
      }
      const team = {};
      for (const col of this.rateCols) {
        team[col] = this.rate.team[col].reasoning;
      }
      members['Team'] = team;
      return members;
    },
  },

  methods: {

    /* individual rate selection of users */
    toggleIndividualMenu(event) {
      this.$refs.individualMenu.toggle(event);
    },

    initIndividualRateMenu() {
      const menuItems = [];
      for (let idx=0; idx<this.rate.members.length; idx++) {
        const user = this.rate.members[idx];
        menuItems.push({
          label: user.name,
          command: () => {
            this.rateSelIdx = idx;
            this.rateSelName = this.rate.members[idx].name;
            this.rateSelDetail = this.rate.members[idx];
          },
        });
      }
      this.rateIndividualMenu = menuItems;
    },

    // Meeting dynamic displays reasoning of rates for selected row
    rateSelect(idx) {
      if (idx === this.rate.members.length) {
        this.rateSelName = "Team";
        this.rateSelDetail = this.rate.team;
      } else {
        this.rateSelIdx = idx;
        this.rateSelName = this.rate.members[idx].name;
        this.rateSelDetail = this.rate.members[idx];
      }
    },

    rateSelectCell(cell) {
      this.rateSelect(cell[0]);
      this.rateColumnIdx = cell[1];
    },

  },
}
</script>

<style scoped>
  .knobs {
    display: flex;
    gap: 5rem;
    justify-content: center;
  }
  .subsection {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-top: 2rem;
    border-top: 1px solid var(--bluegray-200);
  }

  .row-user-selector {
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .tbl-note {
    font-size: 12px;
  }
</style>
