/* helper to create a dynamic TaskEdit dialog */

const statusLabel = {
  pending: 'Pending',
  progress: 'In Progress',
  completed: 'Completed',
  hold: 'On Hold',
};

const statusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'hold', label: 'On Hold' },
  { value: 'progress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' },
];


function dialogOptions(task, saveTask) {
  return {
    props: {
      modal: true,
      header: "Task",
      style: {
        width: '50rem',
      },
    },

    data: {
      task: Object.assign({}, task),
    },
    onClose: () => {
    },
    onCancel: (e) => {
      console.log('task-edit cancel', e);
    },
    save: (d) => {
      saveTask(d);
    },
  };
}

export { statusLabel, statusOptions, dialogOptions };
