<template>
  <div class="form-stacked-field">
    <div class="form-label">
      <label :for="id">{{label}}</label>
      <span v-if="error" class="p-error">{{error}}</span>
    </div>
    <input v-if="type=='password'"
           :id="id"
           class="p-inputtext p-component"
           :class="{'p-invalid': error}"
           type="password"
           :value="modelValue"
           @input="changed"
           @focus="onFocus()" @blur="onBlur()"
           @keyup.enter="$emit('keyEnter')"
           />

    <PriInputText v-else
                  :id="id"
                  ref="input"
                  :class="{'p-invalid': error}"
                  type="text"
                  :value="modelValue"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  @input="changed"
                  @focus="onFocus()" @blur="onBlur()"
                  @keyup.enter="$emit('keyEnter')"
                  />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      required: true,
    },
    id: {
      type: String, // text | password
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'keyEnter'],

  data() {
    return {
      error: null,
    };
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.$el.focus();
      });
    }
  },

  methods: {
    validateAll() {
      if (this.required && !this.modelValue) {
        this.error = 'Required field';
        return false;
      }
      return true;
    },

    onBlur() {
      this.validateAll();
    },

    onFocus() {
      // clear error on focus otherwise user thinks input is still invalid
      this.error = null;
    },

    changed(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  },
};
</script>

<style scoped>
  .form-label {
    display: flex;
    .p-error {
      flex: 1;
      text-align: right;
    }
  }
</style>
