<template>
  <PriTabView>
    <PriTabPanel header="Goals">
      <AnalysisText :items="grpi.goals"/>
    </PriTabPanel>
    <PriTabPanel header="Roles & Responsabilities">
      <AnalysisText :items="grpi.roles"/>
    </PriTabPanel>
    <PriTabPanel header="Processes">
      <AnalysisText :items="grpi.processes"/>
    </PriTabPanel>
    <PriTabPanel header="Interpersonal Relationships">
      <AnalysisText :items="grpi.interactions"/>
    </PriTabPanel>
  </PriTabView>
</template>

<script>

import AnalysisText from '@/common/AnalysisText.vue';

export default {
  components: {
    AnalysisText
  },
  props: {
    grpi: {
      type: Object,
      required: true,
    },
  },
}
</script>
