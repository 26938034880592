import { createRouter, createWebHistory } from 'vue-router';
import boRoutes from './bo';
import HomeAnon from '@/views/HomeAnon.vue';
import TeamList from '@/views/TeamList.vue';
import StaticPage from '@/views/StaticPage.vue';


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home-anon',
      component: HomeAnon,
    },

    ...boRoutes,

    //// account
    {
      path: '/signup',
      name: 'acc-signup',
      component: () => import('../views/AccSignup.vue'),
    },
    {
      path: '/signup/user',
      name: 'acc-signup-user',
      component: () => import('../views/AccSignupUser.vue'),
    },
    {
      path: '/signup/org',
      name: 'acc-signup-org',
      component: () => import('../views/AccSignupOrg.vue'),
    },
    {
      path: '/login',
      name: 'acc-login',
      component: () => import('../views/AccLogin.vue'),
    },
    {
        path: '/forgot-password',
        name: 'acc-password-forgot',
        component: () => import('../views/AccPwdForgot.vue'),
    },
    {
        path: '/reset-password/:token',
        name: 'acc-password-reset',
        component: () => import('../views/AccPwdReset.vue'),
    },
      {
          path: '/sys/error',
          name: 'sys-error',
          component: () => import('../views/SysError.vue'),
      },
      {
      path: '/sys/forbidden/:code',
      name: 'acc-forbidden',
      component: () => import('../views/AccForbidden.vue'),
    },
    {
      path: '/sys/message/:code',
      name: 'acc-message',
      component: () => import('../views/AccMessage.vue'),
    },

    // HOME page for logged users
    {
      path: '/me',
      name: 'user-home',
      component: () => import('../views/UserHome.vue'),
    },

    // TASKS
    {
      path: '/tasks',
      name: 'task-list',
      component: () => import('../views/TaskList.vue'),
    },

    //// team management
    {
      path: '/team',
      name: 'team-list',
      component: TeamList,
    },
    {
      // join team from a invitation link
      // redirected here from signup in case user already logged in
      path: '/team/join',
      name: 'team-join',
      component: () => import('../views/TeamJoin.vue'),
    },
    {
      path: '/team/:id',
      name: 'team-view',
      component: () => import ('../views/TeamView.vue'),
    },
    {
      path: '/team/:id/edit',
      name: 'team-manage',
      component: () => import ('../views/TeamManage.vue'),
    },


    //// retro-app
    {
      path: '/retro/:permalink',
      name: 'retro-app',
      component: () => import('../views/RetroApp.vue'),
    },

    // static pages
    {
      path: '/terms',
      name: 'page-terms',
      component: StaticPage,
    },


    // sys not-found
    {
      path: '/:catchAll(.*)',
      name: 'sys-not-found',
      component: () => import('../views/SysNotFound.vue'),
    },
  ]
})

export default router
