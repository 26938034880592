<template>
  <nav class="menu-items">
    <div v-if="currentRouteName === 'acc-login' ||
               currentRouteName === 'acc-signup' ||
               currentRouteName === 'acc-signup-user'">
      <!-- shows nothing, so user notices the login is being displayed -->
    </div>
    <div v-else-if="!loaded">
      ...
    </div>
    <template v-else>
      <template v-if="accStore.userId!==null">
        <PriMenubar :model="userMenu" :pt="menuPt()"/>
      </template>
      <div v-else>
        <!-- <router-link :to="{ name: 'acc-signup' }" class="menu-item highlighted"> -->
        <!--   signup -->
        <!-- </router-link> -->
        <router-link :to="{ name: 'acc-login' }" class="menu-item">
          login
        </router-link>
      </div>
    </template>
  </nav>
</template>

<script>
import { mapStores } from 'pinia';

import { SpaAxios } from '@/request';
import { useAccStore } from '@/stores/account';

export default {
  name: 'acc-box',
  props: {
  },

  data() {
    return {
      loaded: false,
      currentRouteName: null,
      userMenu: null,
    };
  },

  computed: {
    ...mapStores(useAccStore),
  },

  watch: {
    'accStore.email'() {
      this.setMenu();
    },
  },

  created() {
    this.loadLoggedUser();
    this.$router.beforeEach((to) => {
      this.currentRouteName = to.name;
    });
  },

  methods: {
    loadLoggedUser() {
      SpaAxios.get('/api/auth/info')
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            const payload = data.data;
            this.info = payload;
            this.accStore.init(payload);
            if (this.$posthog) {
              this.$posthog.identify(payload.userId, {
                email: payload.email,
                isAdmin: payload.admin,
              });
            }
          } else {
            this.accStore.setUnauth();
          }
          this.setMenu();
          this.loaded = true;
        })
        .catch((e) => {
          console.error('Internal server error', e);
        });
    },

    submitLogout() {
      SpaAxios.post('/api/auth/logout')
        .then(() => {
          this.accStore.reset();
          if (this.$posthog) {
            this.$posthog.reset();
          }
          this.$router.push({
              name: 'home-anon',
          });
        })
        .catch(() => {
          console.error('Internal server error');
        });
    },

    setMenu() {
      const menu = [];
      if (this.accStore.isAdmin) {
        menu.push({
          label: 'BO',
          command: () => {
            this.$router.push({
              name: 'bo-home',
            });
          },
        });
      }

      const username = {
        label: this.accStore.email,
        items: [
          {
            label: 'logout',
            command: () => {
              this.submitLogout();
            },
          },
        ],
      };
      menu.push(username);
      this.userMenu = menu;
    },

    menuPt() {
      return {
        root: () => ({
          style: {
            border: 'unset',
          },
        })
      }
    },
  },
}

</script>

<style scoped>
</style>
