import mixpanel from 'mixpanel-browser';

export default {
  install(app) {
    mixpanel.init(
      'b9998a765939fde8f9c9753e0ff9e4a6',
      {
        debug: true,
        track_pageview: true,
        persistence: 'localStorage'
      });
    app.config.globalProperties.$mixpanel = mixpanel;
  },
};
