<template>
<div>
  <h1>Terms of Use & Privacy Statements</h1>
  <p>
    <strong>General Information on Data Processing</strong>
  </p>
  <p>This section provides details about how we handle your personal data on Emprove’s platform, dependent on your interactions and the services utilized. We process your personal data only for legitimate purposes and under legal bases, including consent, contract obligations, legal requirements, vital interests, public tasks, and legitimate interests.</p>
  <p>
    <strong>Legal Basis for Processing Personal Data</strong>
  </p>
  <ul>
    <li>Consent (Art. 6(1)(a) GDPR): You agree to the processing of your data for stated purposes, with the right to withdraw consent anytime.</li>
    <li>Contract (Art. 6(1)(b) GDPR): Necessary for the fulfillment of a contract or pre-contractual steps at your request.</li>
    <li>Legal Obligation (Art. 6(1)(c) GDPR): Required to comply with legal obligations.</li>
    <li>Vital Interests (Art. 6(1)(d) GDPR): Essential for protecting vital interests.</li>
    <li>Public Task (Art. 6(1)(e) GDPR): For performing tasks in the public interest or under official authority.</li>
    <li>Legitimate Interests (Art. 6(1)(f) GDPR): Necessary for legitimate interests unless overridden by your rights.</li>
  </ul>
  <p>
    <strong>Your Rights</strong>
  </p>
  <p>You have rights over your personal data, including access, correction, deletion, and the right to object to processing. Specific rights include:</p>
  <ul>
    <li>Access (Art. 15 GDPR): Confirm if your data is being processed and get details about such processing.</li>
    <li>Rectification (Art. 16 GDPR): Correct incomplete or inaccurate data.</li>
    <li>Restriction (Art. 18 GDPR): Limit how your data is processed.</li>
    <li>Erasure (Art. 17 GDPR): Request deletion of your data under certain conditions.</li>
    <li>Data Portability: Transfer your data to another entity.</li>
    <li>Object: Object to data processing in certain circumstances.</li>
    <li>Complaint to Supervisory Authority: Lodge a complaint regarding data processing.</li>
  </ul>
  <p>
    <strong>Data Processing When You Visit Our Website</strong>
  </p>
  <ol>
    <li>Description and Scope of Data Processing</li>
  </ol>
  <ul>
    <li>Every time Emprove’s website is accessed, our system automatically collects data from the accessing device, including browser type and version, operating system, internet service provider, access date and time, data you put in the systems including personal Information such as names, positions, email addresses, and other contact details; meeting notes and data, including any information you input related to meeting agendas, notes, and outcomes.</li>
  </ul>
  <ol>
    <li>Purpose of Data Processing</li>
  </ol>
  <ul>
    <li>To provide and improve our services.</li>
    <li>For customer support and to respond to your inquiries.</li>
    <li>To send updates or information about our services.</li>
    <li>For internal analytics to improve our platform.</li>
    <li>The IP address is stored temporarily to deliver the website to your computer. Log files ensure website functionality and security but are not used for marketing analysis.</li>
  </ul>
  <ol>
    <li>Legal Basis for Data Processing</li>
  </ol>
  <ul>
    <li>The processing is based on Art. 6(1)(f) GDPR, justifying the temporary storage of data and log files.</li>
  </ul>
  <ol>
    <li>Duration of Storage</li>
  </ol>
  <ul>
    <li>Data is deleted when no longer necessary for the purpose of its collection, typically after seven days unless further anonymized for security reasons.</li>
  </ul>
  <ol>
    <li>Exercising Your Rights</li>
  </ol>
  <ul>
    <li>Data collection for website operation is necessary; objections can be made but must be evaluated within the interest balance framework.</li>
  </ul>
  <p>
    <strong>Use of Cookies</strong>
  </p>
  <ol>
    <li>Description and Scope of Data Processing</li>
  </ol>
  <ul>
    <li>Cookies are used to support website functionality and enhance user experience. Both necessary and non-essential cookies may collect data such as language settings, log-in information, and user behavior.</li>
  </ul>
  <ol>
    <li>Purpose of Data Processing</li>
  </ol>
  <ul>
    <li>Necessary cookies ensure website operation. Non-essential cookies help improve the website and tailor marketing strategies.</li>
  </ul>
  <ol>
    <li>Legal Basis for Data Processing</li>
  </ol>
  <ul>
    <li>Necessary cookies are managed under the TTDSG (§ 25(2)(2)). Non-essential cookies require your consent under TTDSG (§ 25(1)) and Art. 6(1)(a) GDPR.</li>
  </ul>
  <p>Cookies are critical for both basic functionalities and advanced features of our website. You have control over your cookie settings and can withdraw consent at any time through our consent manager or your browser settings.</p>
  <p>
    <strong>4. Exercising Your Rights</strong>
  </p>
  <p>You can revoke consent to the use of cookies and manage your preferences at any time.</p>
  <p>
    <strong>Newsletter</strong>
  </p>
  <ol>
    <li>Description and Scope of Data Processing</li>
  </ol>
  <ul>
    <li>Subscribe to our newsletter for updates. We collect your email, last name, first name, IP address, and registration timestamp.</li>
  </ul>
  <ol>
    <li>Purpose of Data Processing</li>
  </ol>
  <ul>
    <li>To deliver newsletters and prevent misuse.</li>
  </ul>
  <ol>
    <li>Legal Basis for Data Processing</li>
  </ol>
  <ul>
    <li>Consent under Art. 6 (1) (a) GDPR.</li>
  </ul>
  <ol>
    <li>Duration of Storage</li>
  </ol>
  <ul>
    <li>Data is stored until no longer needed. Email addresses are stored until you unsubscribe.</li>
  </ul>
  <ol>
    <li>Exercising Your Rights</li>
  </ol>
  <ul>
    <li>Unsubscribe via the link in each newsletter to stop data processing.</li>
  </ul>
  <p>
    <strong>Contact via Email</strong>
  </p>
  <ol>
    <li>Description and Scope of Data Processing</li>
  </ol>
  <ul>
    <li>Contact us via the provided email. We store data transmitted with your email.</li>
  </ul>
  <ol>
    <li>Purpose of Data Processing</li>
  </ol>
  <ul>
    <li>To manage inquiries and requests.</li>
  </ul>
  <ol>
    <li>Legal Basis for Data Processing</li>
  </ol>
  <ul>
    <li>Legitimate interest under Art. 6 (1) (f) GDPR, or Art. 6 (1) (b) GDPR if related to a contract.</li>
  </ul>
  <ol>
    <li>Duration of Storage</li>
  </ol>
  <ul>
    <li>Data is deleted once no longer needed or after the conversation ends.</li>
  </ul>
  <ol>
    <li>Exercising Your Rights</li>
  </ol>
  <ul>
    <li>You can withdraw consent or object to data storage at any time by emailing us at chris.yaowen@gmail.com, leading to the deletion of stored data.</li>
  </ul>
  <p>
    <strong>Contact Form</strong>
  </p>
  <ol>
    <li>Description and Scope of Data Processing</li>
  </ol>
  <ul>
    <li>Emprove's website features a contact form for electronic communication. Information you enter, including email address, last name, first name, and the IP address and timestamp of your device, is transmitted and stored by us.</li>
  </ul>
  <ol>
    <li>Purpose of Data Processing</li>
  </ol>
  <ul>
    <li>We process these personal data to facilitate contact and communication, ensure the integrity of our services, and secure our IT systems.</li>
  </ul>
  <ol>
    <li>Legal Basis for Data Processing</li>
  </ol>
  <ul>
    <li>Processing is based on legitimate interest (Art. 6(1)(f) GDPR) and, where applicable, to initiate or fulfill a contract (Art. 6(1)(b) GDPR).</li>
  </ul>
  <ol>
    <li>Duration of Storage</li>
  </ol>
  <ul>
    <li>Data is retained only as long as necessary to fulfill the stated purposes or until the communication is deemed resolved. Additional data collected during transmission are deleted after seven days.</li>
  </ul>
  <ol>
    <li>Exercising Your Rights</li>
  </ol>
  <ul>
    <li>You may object to data storage or withdraw consent at any time by contacting us at [Emprove's email], resulting in deletion of stored data.</li>
  </ul>
  <p>
    <strong>Email Communication</strong>
  </p>
  <ol>
    <li>Description and Scope of Data Processing</li>
  </ol>
  <ul>
    <li>Direct email communications involve storing data you provide, used solely for handling the correspondence.</li>
  </ul>
  <ol>
    <li>Purpose of Data Processing</li>
  </ol>
  <ul>
    <li>The data helps address your inquiries effectively and manage our interactions.</li>
  </ul>
  <ol>
    <li>Legal Basis for Data Processing</li>
  </ol>
  <ul>
    <li>This is based on legitimate interest for efficient communication (Art. 6(1)(f) GDPR) and contract initiation or fulfillment (Art. 6(1)(b) GDPR).</li>
  </ul>
  <ol>
    <li>Duration of Storage</li>
  </ol>
  <ul>
    <li>Emails and associated data are kept until the related matters are concluded. Supplementary data are removed after seven days.</li>
  </ul>
  <ol>
    <li>Exercising Your Rights</li>
  </ol>
  <ul>
    <li>You can withdraw consent or object to data processing by contacting chris.yaowen@gmail.com, leading to deletion of the associated data.</li>
  </ul>
  <p>
    <strong>Platform</strong>
  </p>
  <p>As a user of our platform, you have certain rights regarding the personal data we collect and process:</p>
  <ul>
    <li>Right to Access: You can request access to your personal data to understand how and why we are using it.</li>
    <li>Right to Rectification: If any personal data is inaccurate or incomplete, you have the right to have it corrected.</li>
    <li>Right to Erasure: You may request the deletion of your personal data when it is no longer necessary for the purposes for which it was collected.</li>
    <li>Right to Restrict Processing: You have the right to request that we restrict the processing of your personal data under certain circumstances.</li>
    <li>Right to Data Portability: You can request a copy of your personal data in a machine-readable format and the right to transmit those data to another controller.</li>
    <li>Right to Object: You have the right to object to the processing of your personal data, particularly for marketing purposes or when the processing is based on our legitimate interests.</li>
  </ul>
  <p>To exercise any of these rights, please contact chris.yaowen@gmail.com. We will respond to your request within the legal time frame.</p>
</div>
</template>

<script>
export default {
  name: 'static-page',
  data() {
    return {
      content: null
    };
  },
  mounted() {
    // do not work!
    // need to get content before app is mounted!
    // this.content = document.getElementById('app').textContent;

    // FIXME: hard-code GDPR page for now
  },
}
</script>

