<template>
  <div class="main">
    <EpSidebar active="team-list"/>
    <div class="content">
      <div class="content-header">
        <h1>My Teams</h1>
        <template v-if="canAdd">
          <PriButton label="New Team" @click="showAddTeam=true"
                     />
          <PriDialog v-model:visible="showAddTeam" modal header="Add Team"
                     :style="{ width: '50rem' }">
            <form @submit.prevent="createTeam">
              <div class="form-stacked-body">
                <FormField id="team-name" label="Team Name:" ref="name"
                           :autofocus="true"
                           required
                           v-model="formAdd.name"
                           />
              </div>
              <div v-if="formError">
                <PriMessage severity="error">{{this.formError}}</PriMessage>
              </div>
            </form>
            <template #footer>
              <PriButton outlined label="Cancel" @click="showAddTeam=false"/>
              <PriButton type="submit" label="Create"
                         @click="createTeam"/>
            </template>
          </PriDialog>
        </template>
      </div>

      <template v-if="loaded">
        <div v-if="teamList.length===0">
          Nothing here
        </div>
        <PriDataTable :value="teamList" class="w-full"
                      selectionMode="single"
                      dataKey="id"
                      @rowSelect="toTeamEdit"
                      :rowClass="(r) => r.canEdit ? '' : 'not-editable'"
                      >
          <PriColumn header="Team">
            <template #body="team">
              <template v-if="team.data.canEdit">
                <i class="pi pi-pencil" style="margin-right: 0.5rem;"></i>
                {{ team.data.name }}
              </template>
              <template v-else>
                {{ team.data.name }}
              </template>
            </template>
          </PriColumn>
          <PriColumn field="role" header="Role" />
          <PriColumn header="Status">
            <template #body="team">
              <template v-if="team.data.status==='active'">
                Active
              </template>
              <template v-else-if="team.data.status==='pending'">
                <PriButton @click="acceptInvitation(team.data)">
                  Accept invite
                </PriButton>
              </template>
            </template>
          </PriColumn>
        </PriDataTable>
      </template>
    </div> <!-- content -->
  </div> <!-- main -->
</template>


<script>
import { mapStores } from 'pinia';

import { SpaAxios } from '@/request';
import { useAccStore } from '@/stores/account';
import EpSidebar from '@/components/EpSidebar.vue';
import FormField from '@/common/FormField.vue';


export default {
  name: 'team-list',
  components: { EpSidebar, FormField },
  computed: {
    ...mapStores(useAccStore), // accStore
  },
  data() {
    return {
      loaded: false,
      canAdd: null,
      teamList: null,
      showAddTeam: false,
      formAdd: {
        name: '',
      },
      formError: null,
    };
  },

  created() {
    this.loadUserTeams();
  },

  methods: {
    toTeamEdit(row) {
      const team = row.data;
      if (team.canEdit) {
        this.$router.push({
          name: 'team-manage',
          params: {id: team.id},
        });
      }
    },

    loadUserTeams() {
      const respProm = SpaAxios.get('/api/team/list')
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            this.canAdd = data.canAdd;
            this.teamList = data.teamList;
            this.loaded = true;
          }
        });
      this.$handleError(respProm);
    },

    createTeam() {
      this.formError = null;
      if (!this.$refs.name.validateAll()) {
        this.formError = 'Invalid input.'
        return;
      }
      const postData = {
        'name': this.formAdd.name,
      };
      const respProm = SpaAxios.post('/api/team/new', postData)
            .then((res) => {
              const { data } = res;
              if (data.status === "success") {
                this.accStore.teams = data.teams; // teamList for sidebar
                this.$router.push({
                  name: 'team-manage',
                  params: {
                    id: data.id,
                  },
                });
              }
            });
      this.$handleError(respProm);
    },

    acceptInvitation(team) {
      const postData = {
        'action': 'accept-invitation',
        'teamId': team.id,
      };
      const url = '/api/team/list';
      const respProm = SpaAxios.post(url, postData)
            .then((res) => {
              const { data } = res;
              if (data.status === "success") {
                team.status = 'active';
              }
            });
      this.$handleError(respProm);
    },

  },
}
</script>

<style>
  .not-editable:hover {
    cursor: initial;
  }
</style>
