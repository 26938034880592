<template>
  <div class="flex-column">
    <PriKnob :modelValue="value"
             :valueColor="rateColor(value)"
             :size="size"
             style="margin: auto"
             />

    <div v-if="info">
      <div class="label" @click="showInfo" style="cursor: pointer;">
        {{label}} <i class="pi pi-info-circle"/>
      </div>
      <PriOverlayPanel ref="infoDetail" style="width:400px;">
        <i class="pi pi-info-circle" style="margin-right: 0.5rem;"/>{{info}}
      </PriOverlayPanel>
    </div>
    <div v-else>
      <div class="label">{{label}}</div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 150,
    },
    info: {
      type: String,
    },
  },
  components: { },

  data() {
    return {
    };
  },

  methods: {
    showInfo(event) {
      this.$refs.infoDetail.toggle(event);
    },

    rateColor(val) {
      // copied from base.css
      //--rate-bad: #E14D37;
      //--rate-weak: #F4CCCB;
      //--rate-good: #A5DED5;
      //--rate-excelent: #21AD96;

      if (val < 60) {
        return '#E14D37'; // bad
      }
      if (val < 75) {
        return '#F4CCCB'; // regular
      }
      if (val < 85) {
        return '#A5DED5'; // good
      }
      return '#21AD96'; // excelent
    }
  },
}
</script>

<style scoped>
  .label {
    text-align: center;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
  }
</style>
