<template>
  <ul class="top-level">
    <li v-for="(item, idx) in items" :key="idx">
      <span style="font-weight: 600;">{{ item.label }}</span>
      <div v-if="typeof item.value === 'string'">{{ item.value }}</div>
      <ul class="inner" v-else>
        <li v-for="(point, pdx) in item.value" :key="pdx">
          {{ point }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
  ul.top-level {
    list-style-position: inside;
    list-style-type: disc;
    li {
      margin: 0.5rem 0;
    }
  }
  ul.inner {
    list-style-type: circle; /* disclosure-closed */
    list-style-position: inside;
    li {
      margin: 0rem 1rem;
    }
  }
</style>
