<template>
  <div class="card-outer" :style="{background: color}">
    <CardVote v-if="stage=='vote'" class="card-head"
              :userVoteRemaining="userVoteRemaining"
              :voteCount="ownVote"
              @votePlus="votePlus" @voteMinus="voteMinus"/>
    <PriBadge v-if="stage=='discuss' || stage==='none'"
              class="vote-badge"
              :value="votes.length"
              severity="info"/>
    <div class="card-main">
      <template v-if="stage=='brainstorm' && hideContent">
        ---
      </template>
      <template v-else>
        <div v-if="editingContent" class="card-edit-content">
          <PriTextarea
            class="card-edit-content"
            ref="contentInput"
            :autoResize="true"
            rows="3"
            v-model="content"
            @keyup.enter="saveContent"
            @keyup.esc="stopEdit"
            />
          <div class="flex">
            <PriButton v-if="stage=='brainstorm' && tasks.length===0 && votes.length===0"
                       icon="pi pi-trash" severity="danger"
                       text rounded outlined
                       @click="deleteCard"
              />
            <PriButton label="Save" size="small" text
                       class="btn-save"
                       @click="saveContent" />
          </div>
        </div>
        <div v-else-if="stage=='none'" class="card-content">
          {{ msg }}
        </div>
        <div v-else class="card-content card-editable" @click="startEdit">
          {{ msg }}
        </div>
      </template>
    </div>
    <div class="card-author">
      {{author}}
    </div>
    <div class="card-tasks" v-if="stage=='discuss' || stage=='none'">
      <template v-for="task in tasks" :key="task.id">
        <CardTask
          :task="task"
          :mode="taskMode"
          @saveTask="$emit('saveTask', $event)"/>
      </template>
      <InPlaceAdd  v-if="stage=='discuss'"
                   label="+ Add Task" @setValue="addAction"/>
    </div>
  </div>
</template>


<script>
import InPlaceAdd from '@/common/InPlaceAdd.vue';
import CardVote from '@/components/CardVote.vue';
import CardTask from '@/components/CardTask.vue';

export default {
  emits: ['setContent', 'deleteCard', 'setVote', 'addAction', 'saveTask'],
  components: { InPlaceAdd, CardVote, CardTask },
  props: {
    userId: {
      type: Number, // RetroUser.id (viewer)
    },
    userVoteRemaining: {
      type: Number,
      required: false,
    },
    stage: {
      type: String,
      required: true
    },
    msg: {
      type: String,
      required: true
    },
    // hide content for other users during brainstorm
    hideContent: {
      type: Boolean,
      default: false,
    },
    votes: { // vote count
      type: Array, // if VOTE - stage own votes, else all votes
      required: false,
    },
    color: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: false,
    },
    tasks: {
      type: Array,
      required: true,
    },
    taskMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editingContent: false,
      content: '',
    };
  },

  computed: {
    ownVote() { // count of own votes
      return this.votes.filter(uid => uid===this.userId).length;
    },
  },

  methods: {
    startEdit() {
      this.content = this.msg;
      this.editingContent = true;
      this.$nextTick(() => {
        this.$refs.contentInput.$el.focus();
      });
    },
    stopEdit() {
      this.editingContent = false;
    },
    saveContent() {
      this.$emit('setContent', this.content);
      this.editingContent = false;
    },
    deleteCard() {
      this.$emit('deleteCard');
    },
    votePlus() {
      this.$emit('setVote', 1);
    },
    voteMinus() {
      this.$emit('setVote', -1);
    },
    addAction(value) {
      this.$emit('addAction', value);
    },
  },
}
</script>

<style scoped>
  .card-outer {
    position: relative; /* required for vote-badge absolute positioning */
    width: 100%;
    border-radius: 6px;

    /* card/shadow */
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20);
  }

  .card-head {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #DDDDDD;
  }

  .card-main {
    display: flex;
    margin: 0.5rem 0.75rem 0.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    color: var(--panel-content-color, #4B5563);

    .card-content {
      /* text-base/semibold/lineheight-auto */
      margin: 0.25rem 0;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.4;

      &.card-editable:hover {
        cursor: pointer;
      }
    }
  }

  .card-author {
    margin: 0 0.75rem 0.2rem;
    font-size: 11px;
    color: #6B7280;
  }

  .btn-save {
    margin-top: 0.25rem;
    margin-left: auto;
  }
  .card-edit-content {
    font-size: 14px;
    width: 100%;
  }

  .card-tasks {
    border-top: 1px solid #CCCCCC;
  }

  .vote-badge {
    position: absolute;
    right: -0.5rem;
    top: -0.25rem;
  }
  .p-inputtext:enabled:focus {
    box-shadow: none;
  }
</style>
