<template>
  <div>
    <div>
      <form @submit.prevent="submitForm" ref="signupForm">
        <div class="form-stacked-field">
          <PriInputText type="email" id="email" v-model="email"
                        placeholder="Your work email"/>
        </div>

        <div style="margin-top: 1.5rem;">
          <PriButton class="w-full" type="submit" label="Start for Free" />
        </div>
      </form>
      <div v-if="error" class="error-message">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { SpaAxios } from '@/request';

export default {
  data() {
    return {
      email: '',
      error: '',
    };
  },

  methods: {
    async submitForm() {
      this.error = ''; // Clear previous errors

      if(!this.$refs.signupForm.reportValidity()) {
        return;
      }
      // Validate form data
      if (!this.email ) {
        this.error = 'Please fill in your email address.';
        return;
      }

      try {
        const resp = await SpaAxios.post('/api/auth/register/email', {
          email: this.email,
        });

        if (this.$mixpanel) {
          this.$mixpanel.track('Click', { name: 'Start for Free' });
        }

        if (resp.data.status === 'success') {
          if (resp.data.isConfirmed) {
            // if already confirmed on signup, came from team invite
            this.$router.push({
              name: 'user-home',
            });
          } else {
            this.$router.push({
              name: 'acc-message',
              params: {code: 'email-confirmation-sent'},
            });
          }
        } else {
          console.log('signup failed', resp);
          this.error = resp.data.message;
        }

      } catch (error) {
        console.error(error);
        // Handle signup error
        this.error = 'Signup failed. Please try again.';
      }
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 10px;
}
</style>
