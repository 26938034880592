<template>
  <PriMenubar :model="orgMenu" :pt="menuPt()"/>
</template>

<script>
import { mapStores } from 'pinia';

import { SpaAxios } from '@/request';
import { useAccStore } from '@/stores/account';

export default {
  name: 'org-selector',
  props: {
    org: Object,
    orgs: Array,
  },

  computed: {
    ...mapStores(useAccStore),
  },

  data() {
    return {
      orgMenu: this.buildMenu(),
    };
  },

  methods: {
    orgSwitch(orgId) {
      const postData = { orgId };
      const respProm = SpaAxios.post('/api/org/switch', postData)
        .then(() => {
          window.location = '/me';
        });
      this.$handleError(respProm);
      if (this.$posthog) {
        this.$posthog.capture('org_switch', {
          orgId,
        });
      }
    },

    menuPt() {
      return {
        root: () => ({
          style: { border: 'unset' },
        })
      }
    },

    buildMenu() {
      if (this.orgs === null) {
        return null;
      }
      const top = {
        label: this.org.name,
      };
      if (this.orgs.length > 1) {
        const items = [];
        for (const org of this.orgs) {
          items.push({
            label: org.name,
            command: () => {
              this.orgSwitch(org.id);
            },
          });
        }
        top.items = items;
      }
      return [ top ];
    },
  },
};
</script>
