<template>
  <div class="container">
    <div v-if="!editing" @click="startEditing" class="placeholder">
      {{ label }}
    </div>
    <template v-else>
      <PriInputText v-if="inputType==='text'"
        ref="input"
        v-model="editedValue" :type="inputType"
        @blur="save" @keyup.enter="save"
        @keyup.esc="stopEditing"
        />
      <PriTextarea v-else
                   ref="input"
                   :autoResize="true"
                   rows="3"
                   v-model="editedValue"
                   @blur="save" @keyup.enter="save"
                   @keyup.esc="stopEditing"
        />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
  },
  emits: ['setValue'],
  data() {
    return {
      editing: false,
      editedValue: this.modelValue,
      canSubmit: false, // guard to avoid double submit
    };
  },

  methods: {
    startEditing() {
      this.canSubmit = true;
      this.editing = true;
      this.$nextTick(() => {
        this.$refs.input.$el.focus();
      });
    },
    stopEditing() {
      this.editing = false;
    },
    save(event) {
      if (this.canSubmit === false) {
        return; // make sure enter+blur are not both called
      }
      const value = event.target.value.trim();
      if (!value) {
        this.editing = false;
        return;
      }
      this.canSubmit = false;
      this.$emit('setValue', value);
      this.editedValue = '';
      this.editing = false;
    }
  }
};
</script>

<style scoped>


.container {
  --padding-size: 8px;
  background: var(--input-background, #FFF);
  width: 100%;
  font-size: 14px;
  height: var(--height);
}

.placeholder {
  border-radius: 6px;
  border: 1px solid var(--input-borderColor, #D1D5DB);
  cursor: pointer;
  padding: var(--padding-size);
  color: var(--placeholder-color);
}

.placeholder:hover {
  background: rgb(255, 213, 79);
}

textarea, input {
  width: 100%;
  outline: none;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}
</style>
