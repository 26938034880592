<template>
  <table>
    <thead>
      <tr>
        <th></th>
        <th v-for="(col, idx) in columns" :key="idx">
          <span class="capitalize">{{col}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, ri) in rows" :key="ri" :class="{selected: selected===ri}">
        <th>
          <!-- @click="selectRow(ri)" -->
          <span>{{row}}</span>
        </th>
        <td v-for="(val, ci) in values[ri]" :key="ci"
            :class="cellColor(val)">
          <div :class="{link: selectableCell, selectedCell: isCellSelected(ri, ci)}"
               @click="selectCell(ri, ci)">
            {{val}}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>


<script>
export default {
  emits: [ 'selectRow', 'selectCell' ],
  props: {
    columns: {
      type: Array, // [String]
      required: true,
    },
    rows: {
      type: Array, // [String]
      required: true,
    },
    values: {
      required: true,
    },
    selectableCell: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Number, // selected row index
    },
    selectedColumn: {
      type: Number, // selected column
    },
  },
  components: { },

  data() {
    return {
    };
  },

  methods: {
    selectRow(rowIdx) {
      this.$emit('selectRow', rowIdx);
    },

    selectCell(rowIdx, colIdx) {
      this.$emit('selectCell', [rowIdx, colIdx]);
    },

    isCellSelected(ri, ci) {
      return (ri === this.selected && ci === this.selectedColumn);
    },

    cellColor(val) {
      if (val < 60) {
        return 'bad';
      }
      if (val < 75) {
        return 'regular';
      }
      if (val < 85) {
        return 'good';
      }
      return 'excelent';
    }
  },
}
</script>

<style scoped>
  table {
    border-collapse: collapse;
  }
  td {
    text-align: center;
    vertical-align: middle;
    padding: 8px 2px;
    border: 3px solid white;
    font-weight: 600;
  }
  th {
    padding: 0 8px;
  }
  tbody th {
    text-align: left;
  }

  tr.selected {
    background-color: var(--purple-100);
    outline-offset: 2px;
    border-radius: 5px;
  }

  .selectedCell {
    color: purple;
  }

  .bad {
    color: white;
    background-color: var(--rate-bad);
  }
  .regular {
    background-color: var(--rate-weak);
  }
  .good {
    background-color: var(--rate-good);
  }
  .excelent {
    color: white;
    background-color: var(--rate-excelent);
  }

  .link {
    cursor: pointer;
  }
</style>
