<template>
  <header class="header">
    <div class="header-nav">
      <RouterLink :to="{ name: 'user-home' }" class="logo">
        <img alt="Emprove logo" src="/logo.png" height="48" width="212"/>
      </RouterLink>
      <span class="beta">BETA</span>
      <div id="view-head" class="header-view-nav"></div>
      <nav class="menu-items">
        <!-- <RouterLink class="menu-item" to="/">Retrospective</RouterLink> -->
        <!-- <RouterLink class="menu-item" :to="{ name: 'team-list' }">Team</RouterLink> -->
      </nav>
      <AccBox/>
    </div>
  </header>
  <ConfirmDialog/>
  <DynamicDialog/>
  <PriToast />
  <RouterView/>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
import DynamicDialog from 'primevue/dynamicdialog';
import AccBox from './components/AccBox.vue';

export default {
  name: 'app',
  components: { ConfirmDialog, DynamicDialog, AccBox },
  data() {
    return {
      loaded: false,
    };
  },

  computed: {
  },

  created() {
  },

  methods: {
  },
}

</script>

<style scoped>
/*** header */
.header {
  display: flex;
  width: 100%;
  padding: 7px;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  border: 1px solid #E5E7EB;
  background: #F9FAFB;
}

.logo {
  display: flex;
  width: 113.58px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.beta {
  font-size: 12px;
  align-self: end;
  padding-right: 2rem;
  font-weight: 600;
  color: var(--orange-600);
}

.header-nav {
  padding: 0 0.5rem;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.header-view-nav { /* view specific, set through teleport */
  margin-left: 1rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.header .menu-items {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

/*** top menu bar menu-item */
/** note: fix hover to be pseudo-class */
.header .menu-item {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  padding: 16px 20px;
  position: relative;
}

.header .menu-item {
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .menu-item {
  color: #00000080;
}
.header .menu-item:hover {
  color: #000000;
}

.header .menu-item.highlighted {
  border-radius: 8px;
  background-color: #000000;
  color: #ffffff;
}
.header .menu-item:hover.highlighted {
  background-color: #3e3c3c;
}
/** menu-item end */
/*** header - end */
</style>
