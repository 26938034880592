<template>
  <div @click="editTask(task)"
       class="task-container"
       :class="{ 'is-link': mode!='read-only' }">
    <i class="hover-reveal pi pi-pencil" :class="{hide: mode=='read-only' }"/>
    <input type="checkbox" disabled :checked="task.status=='completed'"/>
    <span class="task-main">
      {{ task.content }}
    </span>
  </div>
</template>

<script>
import * as Task from '@/task';
import TaskEdit from '@/components/TaskEdit.vue';

export default {
  emits: ['saveTask'],
  props: {
    task: {
      type: Object,
      required: true,
    },
    mode: {
      // meet: during the meeting
      // view: after the meeting
      // read-only: for demo page
      type: String,
      required: true,
    }
  },

  data() {
    return {
    };
  },

  methods: {
    editTask(task) {
      if (this.mode === 'read-only') {
        return;
      }

      const save = (savedTask) => {
        this.$emit('saveTask', savedTask);
      };
      this.$dialog.open(TaskEdit, Task.dialogOptions(task, save));
    },
  },
}
</script>

<style scoped>
  .hide {
    display: none;
  }
  .task-container {
    display: flex;
    margin: 8px 12px;
    align-items: flex-start;
    gap: 6px;
    align-items: center;
    color: var(--panel-content-color, #4B5563);

    /* text-base/semibold/lineheight-auto */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.is-link {
      cursor: pointer;
    }
  }
  .hover-reveal {
    visibility: hidden;
  }
  .task-container:hover .hover-reveal {
    visibility: visible;
  }
  .task-main {
    flex-grow: 1;
    padding: 0;
    text-align:left;
  }
</style>
