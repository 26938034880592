<template>
  <nav class="sidebar">
    <!-- menu -->
    <div class="app-menu" style="width:100%;">
      <router-link :to="{ name: 'user-home' }"
                   class="menu-item"
                   :class="{ active: active==='user-home'}">
        <div class="menu-link">
          Home
        </div>
      </router-link>
      <router-link :to="{ name: 'task-list' }"
                   class="menu-item"
                   :class="{ active: active==='task-list'}"
                   >
        <div class="menu-link">
          Tasks
        </div>
      </router-link>

      <span class="menu-item menu-group">
        My Teams
      </span>
      <template v-for="team in teamsByName()" :key="team.id">
        <router-link :to="{ name: 'team-view', params: { id: team.id} }"
                     class="menu-item menu-sub"
                     :class="{ active: active===`team-${team.id}`}">
          <div class="menu-link">
            {{team.name}}
          </div>
        </router-link>
      </template>
    </div>

    <!-- Org BOX -->
    <PriDivider align="left" type="solid">
    </PriDivider>
    <template v-if="accStore.loaded && accStore.org">
      <OrgSelector class="menu-company"
                   :org="accStore.org" :orgs="accStore.orgs"/>
    </template>
    <div style="width:100%;">
      <span class="menu-item menu-group">
        Manage
      </span>
      <router-link
        id="manage-team"
        :to="{ name: 'team-list' }"
        class="menu-item menu-sub"
        :class="{ active: active==='team-list'}"
        >
        <div class="menu-link">
          Teams
        </div>
      </router-link>
    </div>
  </nav>
</template>


<script>
import { mapStores } from 'pinia';

import { useAccStore } from '@/stores/account';
import OrgSelector from '@/components/OrgSelector.vue';

export default {
  components: { OrgSelector },
  computed: {
    ...mapStores(useAccStore),
  },
  props: {
    active: String,
  },

  data() {
    return {
      orgMenu: null,
    };
  },

  methods: {
    teamsByName() {
      if (this.accStore.teams === null) {
        return [];
      }
      const sortByName = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      const teamList = Object.values(this.accStore.teams);
      teamList.sort(sortByName);
      return teamList;
    },
  },
}
</script>

<style scoped>
  .sidebar {
    padding: 7px 0px 14px;
    height: calc(100vh - 120px); /* 120px header height */
    min-width: 200px;
    overflow-y: auto;
    position: sticky;
    top: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--menu-borderColor, #E5E7EB);
    background: var(--menu-background, #FFF);
  }

  .app-menu {
    flex: 1 auto;
  }

  .menu-company {
    align-self: center;
    color: var(--submenu-header-color, #374151);
    /* submenu/header/typography */
    /* font-family: Inter;   install font */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

</style>
