import axios from 'axios';
import epversion from './ep_version.txt?raw';
import ConfirmSimple from '@/common/ConfirmSimple.vue';

const SpaAxios = axios.create();
SpaAxios.interceptors.response.use(
  (response) => {
    const serverVersion = response.headers['spa-version'];
    if (serverVersion && serverVersion !== epversion.trim()) {
      console.log('SPA version: (client, server)', epversion, serverVersion);
      throw new Error('SPA version');
    }
    return response;
  });

/* TODO: also need to track failures of loading VUE components dynamically

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
    window.location = to.fullPath
  }
})
*/


function warnNeedRefresh(dialog) {
  // check SPA is not older version than server built
  dialog.open(ConfirmSimple, {
    props: {
      header: 'Refresh Page',
      style: {
        width: '500px',
      },
      closable: false,
      modal: true,
    }
  });
}

// global error handler for ajax requests
// param resp is a Promise returned by axios.get / axios.post
function handleError(promise, router, toast, dialog) {

  promise.catch((error) => {
    if (error.message === 'SPA version') {
      warnNeedRefresh(dialog);
      return;
    }

    if (error.response === undefined) {
      throw error; // JS code error
    }
    if (error.response.status === 401) { // and unauth error:
      router.push({name: 'acc-login'})
      return;
    }
    if (error.response.status === 403) { // forbidden
      if (error.response.data.message==='org-creation-required') {
        router.push({
          name: 'acc-signup-org',
          params: { code: error.response.data.message},
        })
        return;
      }
      router.replace({
        name: 'acc-forbidden',
        params: { code: error.response.data.message},
      })
      return;
    }
    if (error.response.status === 302) { // redirect
      if (!('redirectTo' in error.response.data)) {
        console.log('Error data:', error.response.data);
        console.error('redirect-302 must include `redirectTo` data');
        return;
      }
      router.push(error.response.data.redirectTo);
      return;
    }

    // catch all error message
    console.error(error.response);
    if (error.response.status === 500) {
      if (toast) {
        toast.add({
          summary: 'Internal Server Error',
          severity: 'error',
          life: 5000,
        });
      } else {
        router.replace({ name: 'sys-error' });
      }
      return;
    }
    // unknown error code
    if (toast) {
      toast.add({
        summary: `Error ${error.response.status}`,
        severity: 'error',
        life: 5000,
      });
    }

  });
}


function vueInstallAjaxErrorHandler(app) {
  app.config.globalProperties.$handleError = function(promise) {
    return handleError(promise, this.$router, this.$toast, this.$dialog);
  }
}

export { SpaAxios, vueInstallAjaxErrorHandler };
