<template>
  <div class="hero-container">
    <img alt="Emprove logo" src="/logo.png" height="48" width="212"/>
    <h2 class="hero-heading">
      Lead meetings with ease, <br/> and enable teams with confidence
    </h2>
    <p class="hero-subtext">
      Your go-to retrospective meeting platform for team alignment <br/>and AI-based management insights.
    </p>
  </div>
  <div class="sys-main">
    <div class="sys-content">
      <SignupCall />
    </div>
  </div>

  <div class="how-container" v-if="loadedDemo && demo!==null">
    <h2 class="hero-heading">How it works?</h2>
    <h3>
      Pick one of our curated meeting template boards (or create a custom one!)
    </h3>
    <div class="template-section">
      <BoardTemplateCard
        v-for="item in templates" :key="item.name"
        :data="item"
        />
    </div>
    <h3>Conduct a meeting online</h3>
    <p>Follow the meeting structure in 4 steps</p>
    <div class="video-container">
      <iframe width="854" height="480"
              src="https://www.youtube.com/embed/kC6eif3LE0o"
              style="margin: auto;"
              frameborder="0" allowfullscreen>
      </iframe>
    </div>
    <div class="full-width">
      <h3>Meeting board (sample after meeting)</h3>
      <div class="board block">
        <RetroBoardHeader :columns="board.columns"/>
        <div class="board-body">
          <div v-for="(col, colIdx) in board.columns" :key="colIdx"
               class="board-body-col">
            <div class="board-col-content board-col-static">
              <div class="cards-frame">
                <template v-for="card in colCards(colIdx)" :key="card.id">
                  <BoardCard stage="none"
                             :msg="card.content"
                             :author="users[card.authorId].nick"
                             :color="cardColor(colIdx)"
                             :votes="card.votes"
                             :tasks="card.tasks"
                             taskMode="read-only"
                             />
                </template>
              </div>
            </div>
          </div> <!-- board-col -->
        </div> <!-- board-body -->
      </div> <!-- board -->

      <h3>AI Analysis</h3>
      <div class="ai-box">
        <div class="ai-left">
          <a v-for="item in aiMenu"
               :key="item.key"
               @click="aiActive=item.key"
               class="menu-item"
               :class="{active: aiActive==item.key}">
            <span class="menu-link">{{ item.label }}</span>
          </a>
        </div>
        <div class="ai-right">
          <MeetingDigest v-if="aiActive=='digest'" :data="result.digest"/>
          <MeetingGrpi v-if="aiActive=='grpi'" :grpi="result.grpi"/>
          <MeetingIndividualAnalysis
            v-if="aiActive=='rate'"
            :rate="result.rate"
            :viewIndividual="true"/>
        </div>
      </div>
    </div>

    <h3>Visualise trends</h3>
    <p>Coming Soon...</p>
  </div>

  <div class="sys-main">
    <div class="sys-content">
      <p class="hero-subtext" style="margin-bottom: 1rem;">
        Get started now
      </p>
      <SignupCall />
    </div>
  </div>

  <!-- <div v-if="anonEnabled"> -->
  <!--   <div class="acc-card"> -->
  <!--     <form @submit.prevent="createAnonRetro" ref="createForm"> -->
  <!--       <h2>Create Anonymous Retro</h2> -->
  <!--       <div class="form-stacked-field"> -->
  <!--         Email: -->
  <!--         <PriInputText type="email" required v-model="formData.email" /> -->
  <!--       </div> -->
  <!--       <div class="form-stacked-field"> -->
  <!--         Retro name: -->
  <!--         <PriInputText type="text" required v-model="formData.name" /> -->
  <!--       </div> -->
  <!--       <div class="form-stacked-field"> -->
  <!--         User nickname: -->
  <!--         <PriInputText type="text" required v-model="formData.nick" /> -->
  <!--       </div> -->
  <!--       <PriButton type="submit">Submit</PriButton> -->
  <!--     </form> -->
  <!--   </div> -->
  <!-- </div> -->
</template>

<script>
import { mapStores } from 'pinia';

import { SpaAxios } from '@/request';
import { useAccStore } from '@/stores/account';
import SignupCall from '@/components/SignupCall.vue';
import BoardTemplateCard from '@/components/BoardTemplateCard.vue';
import RetroBoardHeader from '@/components/RetroBoardHeader.vue';
import BoardCard from '@/components/BoardCard.vue';
import MeetingDigest from '@/components/MeetingDigest.vue';
import MeetingGrpi from '@/components/MeetingGrpi.vue';
import MeetingIndividualAnalysis from '@/components/MeetingIndividualAnalysis.vue';


export default {
  name: 'home-anon',
  components: {
    SignupCall, BoardTemplateCard,
    RetroBoardHeader, BoardCard,
    MeetingDigest, MeetingGrpi, MeetingIndividualAnalysis
  },
  data() {
    return {
      // create anon - meeting
      // anonEnabled: false,
      // formData: {
      //   email: null,
      //   name: null,
      //   nick: null,
      // },
      loadedDemo: false,
      demo: null,
      templates: null,
      board: null,
      cardPos: null,
      cards: null,
      result: null,
      aiActive: 'digest',
      aiMenu: [
        { key: 'digest', label:'Meeting Digest' },
        { key: 'grpi', label:'Team Analysis - GRPI' },
        { key: 'rate', label:'Meeting Dynamics' },
      ],
    };
  },

  computed: {
    ...mapStores(useAccStore),
  },

  created() {
    // wait till logged user info is available
    if (this.accStore.loaded) {
      this.loggedRedirect();
      return;
    }
    // https://pinia.vuejs.org/core-concepts/state.html#Subscribing-to-the-state
    this.accStore.$subscribe(() => {
      this.loggedRedirect();
    });
  },

  mounted() {
    this.getDemo();
    if (this.$mixpanel) {
      this.$mixpanel.track('Open page', { name: 'Landing page' });
    }
  },

  methods: {
    loggedRedirect() {
      if (this.accStore.loaded && this.accStore.userId) {
        this.$router.push({ name: 'user-home' });
        return;
      }
    },

    getDemo() {
      const respProm = SpaAxios.get('/api/land/demo')
            .then((res) => {
              const { data } = res;
              if (data.name) {
                this.templates = data.templates;
                this.demo = data.name;
                this.board = data.board;
                this.cardPos = data.cardPos;
                this.cards = data.cards;
                this.users = data.users;
                this.result = data.result;
                this.loadedDemo = true;
              } else {
                this.demo = null;
              }
            });
      this.$handleError(respProm);
    },

    colCards(colIdx) {
      const items = this.cardPos[colIdx].map(id => this.cards[id]);
      return items.sort((a, b) => b.votes.length - a.votes.length);
    },

    cardColor(colIdx) {
      return this.board.columns[colIdx].color;
    },


    createAnonRetro() {
      if(!this.$refs.createForm.reportValidity()) {
        return;
      }
      const respProm = SpaAxios.post('/api/retro/new/anon', this.formData)
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            this.$router.push({
              name: 'retro-app',
              params: { permalink: data.permalink }
            });
          } else {
            console.log(data);
            throw new Error("Not implemented");
          }
        });
      this.$handleError(respProm);
    },
  },
}
</script>

<style scoped>
.hero-container {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
}
.hero-heading {
  color: var(--dark);
  margin-top: 1rem;
  margin-bottom: 24px;
  font-size: 50px;
  font-weight: 600;
  line-height: 111%;
  text-align: center;
}
.hero-subtext {
  max-width: 820px;
  color: var(--dark);
  margin: 0 -10px;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
}

.how-container {
  margin: 2rem auto;

  h3 {
    margin: 2rem 0 1rem;
    color: var(--dark);
    font-size: 24px;
    line-height: 160%;
  }
}

.video-container {
  display: flex;
}

.template-section {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.ai-box {
  display: flex;
  border: 1px solid var(--bluegray-300);
}

.ai-left {
  width: 300px;
  flex-shrink: 0;
  background: white;
  border-right: 1px solid var(--bluegray-300);
}
.ai-right {
  flex: 1 auto;
}
</style>
