<template>
  <PriTabView>
    <PriTabPanel header="Top Highlights">
      <ul>
        <li v-for="(item, idx) in data['highlights']" :key="idx">
          {{ item }}
        </li>
      </ul>
    </PriTabPanel>
    <PriTabPanel header="Top Concerns">
      <ul>
        <li v-for="(item, idx) in data['concerns']" :key="idx">
          {{ item }}
        </li>
      </ul>
    </PriTabPanel>
    <PriTabPanel header="Top Focus">
      <ul>
        <li v-for="(item, idx) in data['focus']" :key="idx">
          {{ item }}
        </li>
      </ul>
    </PriTabPanel>
  </PriTabView>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>


<style scoped>
  ul {
    list-style-position: inside;
    list-style-type: disc;
    li {
      margin: 0.5rem 0;
    }
  }
</style>
